import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d8067ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-title" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "login-body" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mail-field mt-5" }
const _hoisted_8 = { class: "group-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_BorderButton = _resolveComponent("BorderButton")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("login.forgotPassTagline")), 1),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("login.forgotPassTitle")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", {
        innerHTML: _ctx.$t('login.forgotPassText'),
        class: "forgot-pass-text mt-5"
      }, null, 8, _hoisted_6),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("form", {
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createVNode(_component_ValidationTextField, {
            name: "email",
            rules: "required|email",
            placeholder: _ctx.$t('common.email'),
            "keep-error-space": false,
            message: {
              error_required: _ctx.$t('errors.required'),
              error_format_email: _ctx.$t('errors.formatEmail'),
            }
          }, null, 8, ["placeholder", "message"])
        ], 32)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BorderButton, {
          text: _ctx.$t('login.forgotPassCancelBtn'),
          class: "forgot-cancel-btn",
          onOnClick: _ctx.cancelForgotPassword
        }, null, 8, ["text", "onOnClick"]),
        _createVNode(_component_FlatButton, {
          enabled: _ctx.form.meta.value.valid,
          text: _ctx.$t('login.authenticate'),
          class: "forgot-confirm-btn",
          "on-click": _ctx.submit
        }, null, 8, ["enabled", "text", "on-click"])
      ])
    ])
  ]))
}