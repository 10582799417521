
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import BorderButton from "@/components/atomics/button/BorderButton.vue";
import { defineComponent } from "vue";
import { ForgotPasswordRepository, RepositoryFactory } from "@/lib/https";
import router from "@/router";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    ValidationTextField,
    FlatButton,
    BorderButton,
  },
  setup() {
    const { requestForgotPasswordCode } =
      RepositoryFactory.getRepository<ForgotPasswordRepository>(
        ForgotPasswordRepository
      );

    const { formHandle, form } = useFormHandler(
      { email: "" },
      requestForgotPasswordCode
    );

    const submit = async () => {
      await formHandle()
        .then(() => {
          localStorage.setItem("emailForgotPassword", form.values.email);
          router.push({ name: "ForgotPasswordConfirm" });
        })
        .catch((e) => {
          form.setErrors({ email: e.data.message });
        });
    };

    const cancelForgotPassword = () => {
      router.push({ name: "Login" });
    };

    return {
      submit,
      form,
      cancelForgotPassword,
    };
  },
});
