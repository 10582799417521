import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc0fa196"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button border_button", { 'icon-button': _ctx.icon, disabled: !_ctx.enabled }]),
    disabled: !_ctx.enabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "icon",
          src: _ctx.icon
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ], 10, _hoisted_1))
}